import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Cookies from "universal-cookie";
import { TTS_CENTER_BANNER_COOKIE } from "../../configs/keys";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import get from "lodash/fp/get";
import axios from "../../api/axios";
import { updateCommonState } from "../../redux/actions/common.action";
import { baseCookieConfig } from "../../helpers/storage/cookie";

const CenterBanner = props => {
  const dispatch = useDispatch();
  const centerBanner = useSelector(({ common }) => common.centerBanner);
  const banner = get("ads_content[0]", centerBanner);
  const [show, setShow] = useState(false);

  useEffect(() => {
    axios
      .get(`/uab/api/get?position=web_mobile_modal_center&platform=web_mobile`)
      .then(resp => {
        const centerBanner = get("data.data[0]", resp, null);
        dispatch(updateCommonState("centerBanner", centerBanner));
      });

    const cookie = new Cookies();
    const showed = parseInt(cookie.get(TTS_CENTER_BANNER_COOKIE));
    if (!showed) {
      setShow(true);
    }
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    const cookie = new Cookies();
    const options = {
      ...baseCookieConfig,
      maxAge: 3600 * 4, // 4 hours
    };
    cookie.set(TTS_CENTER_BANNER_COOKIE, "1", options);
  }, []);

  if (!banner) {
    return null;
  }

  return (
    <>
      <Modal open={show}>
        <div className="ts-center-modal__background">
          <div className="ts-center-modal__content">
            <HighlightOffIcon
              style={{
                color: "#fff",
                display: "block",
                marginLeft: "auto",
                marginRight: 20,
                fontSize: "2rem",
              }}
              onClick={handleClose}
            />
            <a href={banner.link} target="_blank">
              <img className="ts-center-modal__image" src={banner.banner} />
            </a>
          </div>
        </div>
      </Modal>
      <style jsx>{`
        .ts-center-modal__background {
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          position: fixed;
          background-color: rgba(0, 0, 0, 0.4);
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          place-content: center;
          -webkit-box-pack: center;
          z-index: 9000;
        }
        .ts-center-modal__content {
          -webkit-box-flex: 0;
          flex: 0 1 auto;
          position: relative;
          width: 80%;
          max-width: 438px;
          max-height: 100%;
        }
        .ts-center-modal:focus {
          outline: none;
        }
        .ts-center-modal__image {
          max-width: 100%;
        }
      `}</style>
    </>
  );
};

export default React.memo(CenterBanner);
